import localforage from "localforage";
import Accounting from "@/helpers/Accounting";
import Utils from "@/helpers/Utils";

export default {
    // eslint-disable-next-line no-unused-vars
    async cache(key, func, uuid) {
        return new Promise(resolve => {
            localforage.getItem(key + ":" + uuid)
                .then(async value => {
                    if (value == null || value.title == null) {
                        const title = await func(uuid);
                        await localforage.setItem(key + ":" + uuid, {
                            title: title,
                            date: new Date()
                        });
                        resolve(title);
                    } else {
                        resolve(value.title);
                    }
                }).catch(async () => {
                resolve(await func(uuid));
            });
        });
    },
    async getCustomer(uuid) {
        let req = await Accounting.get("/contacts/" + uuid).catch(() => {
        });
        if (req !== undefined && req.status === 200) {
            return req.data.name;
        } else {
            let title = uuid;

            if (uuid === this.$store.state.company.uuid) {
                title = this.$store.state.company.name;
            }

            return title;
        }
    },
    async getInvoice(uuid) {
        let req = await Accounting.get("/incomes/invoices/" + uuid).catch(() => {
        });
        if (req !== undefined && req.status === 200) {
            return req.data.invoice_number;
        } else {
            return uuid;
        }
    },
    async getRevenue(uuid) {
        let req = await Accounting.get("/incomes/revenues/" + uuid).catch(() => {
        });
        if (req !== undefined && req.status === 200) {
            let title = Utils.formatMoney(req.data.amount, req.data.currency_code, this.$i18n.locale);

            if (req.data.reference !== null) {
                title = req.data.reference + " (" + title + ")";
            }

            return title;
        } else {
            return uuid;
        }
    },
    async getPayment(uuid) {
        let req = await Accounting.get("/expenses/payments/" + uuid).catch(() => {
        });
        if (req !== undefined && req.status === 200) {
            let title = Utils.formatMoney(req.data.amount, req.data.currency_code, this.$i18n.locale);

            if (req.data.reference !== null) {
                title = req.data.reference + " (" + title + ")";
            }

            return title;
        } else {
            return uuid;
        }
    },
    async getBill(uuid) {
        let req = await Accounting.get("/expenses/bills/" + uuid).catch(() => {
        });
        if (req !== undefined && req.status === 200) {
            return req.data.bill_number;
        } else {
            return uuid;
        }
    },
    async getDocument(uuid) {
        let req = await Accounting.get("/documents/" + uuid).catch(() => {
        });
        if (req !== undefined && req.status === 200) {
            return req.data.name;
        } else {
            return uuid;
        }
    },
    async getAccountingCode(uuid) {
        let req = await Accounting.get("/accounting/codes/" + uuid).catch(() => {
        });
        if (req !== undefined && req.status === 200) {
            const data = req.data;
            return data.code + " - " + data.description;
        } else {
            return uuid;
        }
    }

};